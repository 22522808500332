import React from "react"


import Layout from '../components/layout'
import { PriceService } from '../components/priceService'
import { SmallTitlePage } from '../components/common/smallTitlePage'
import { useStaticQuery, graphql } from 'gatsby'
import Seo from '../components/seo'



const LodzCennik = () => {

  const data = useStaticQuery(graphql`
  {
    file(relativePath: {eq: "bodymedgroup-bydgoszcz.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            
          )
        }
      }
    }
`)

  return (
    <Layout lodz>
      <Seo
        title="Lodz cennik"
        description="Cennik zabiegów w BodyMed Group Lodz."
        url="https://bodymedgroup.pl/lodz-blog"
      />
      <SmallTitlePage
        title='Cennik' 
      />
      <PriceService lodz/>
    </Layout>
  )
}

export default LodzCennik
