import React, { useState } from 'react'
import styled from 'styled-components'
import { Container } from '../common/container'
import { Wrapper } from '../common/wrapper'
import { Heading } from '../common/heading'
import { useStaticQuery, graphql } from 'gatsby'

import { AccordionPrice } from './accordion'




const StyledWrapper = styled.div`
    background: ${({ theme }) => theme.colors.grayWhite};
    padding: 0 5.5vw;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
`

const StyledContainer = styled.div `
    width: 100%;
    padding: 3rem 0;
    display: flex;
    justify-content: center;
`

const ContainerAccordions = styled.div `
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1366px;

    .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
        display: none;
    }

    .css-67l5gl:before {
        display: none;
    }

`



export const PriceService = ({ lodz, torun, bydgoszcz }) => {

    const data = useStaticQuery(graphql`

    {
        allContentfulLodzCennik(sort: {fields: kolejnosc}) {
          nodes {
            id
            nazwa
            cennik {
              childMarkdownRemark {
                html
              }
            }
          }
        }

        allContentfulTorunCennik(sort: {fields: kolejnosc}) {
            nodes {
              id
              nazwa
              cennik {
                childMarkdownRemark {
                  html
                }
              }
            }
        }

        allContentfulBydgoszczCennik(sort: {fields: kolejnosc}) {
            nodes {
            id
            nazwa
                cennik {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
      }
    
    `)

    const lodzPrices = data?.allContentfulLodzCennik.nodes
    const torunPrices = data?.allContentfulTorunCennik.nodes
    const bydgoszczPrices = data?.allContentfulBydgoszczCennik.nodes

    if (lodz) {
        return (
            <StyledWrapper>
                <StyledContainer>
                    <ContainerAccordions>
                        {lodzPrices.map((lodzPrice) => {
                            return (
                                <AccordionPrice key={lodzPrice.id} price={lodzPrice}/>
                            )
                        })}
                    </ContainerAccordions>
                </StyledContainer>
            </StyledWrapper>
        )
    }
    else if (bydgoszcz) {
        return (
            <StyledWrapper>
                <StyledContainer>
                    <ContainerAccordions>
                        {bydgoszczPrices.map((bydgoszczPrice) => {
                            return (
                                <AccordionPrice key={bydgoszczPrice.id} price={bydgoszczPrice}/>
                            )
                        })}
                    </ContainerAccordions>
                </StyledContainer>
            </StyledWrapper>
        )
    }

    else if (torun) {
        return (
            <StyledWrapper>
                <StyledContainer>
                    <ContainerAccordions>
                        {torunPrices.map((torunPrice) => {
                            return (
                                <AccordionPrice key={torunPrice.id} price={torunPrice}/>
                            )
                        })}
                    </ContainerAccordions>
                </StyledContainer>
            </StyledWrapper>
        )
    }


}