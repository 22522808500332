import React, { useState } from 'react'
import styled from 'styled-components'
import { Heading } from '../common/heading'

//material-ui

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

import { MdKeyboardArrowDown } from 'react-icons/md'


const StyledAccordion = styled(Accordion) `
  width: 100% !important;
  -webkit-box-shadow: 0px 3px 6px 3px rgba(0,0,0,0.05) !important; 
  box-shadow: 0px 3px 6px 3px rgba(0,0,0,0.05) !important; 
  border-radius: 5px !important; 
  border-top: 0px;
  border: 1px solid white !important; 
  margin: 1rem 0;

  :last-child {
    
  }

`


const StyledHeadingDescriptionTreatment = styled(Heading) `
    font-weight: 500;
    color: ${({ theme }) => theme.colors.green};
    font-size: 1.1rem;
`

const AccordionDetail = styled(AccordionDetails) `
    display: flex;
    flex-direction: column;

  div {
      display: block;
      overflow-x: auto;

      table {
        width: 100%;
        border: 1px solid black;
        

        th {
          background: ${({ theme }) => theme.colors.green};
          color: #fff;
          min-width: 203px;
          box-sizing: border-box;
          padding: 1rem;
          border: 1px solid black;
        }

        tr:nth-child(even) {
          background-color: #F0F0F0;
        }

        tr {
            background-color: #fff;
          
          td {
            min-width: 250px;
            line-height: 120%;
           
            box-sizing: border-box;
            padding: 0.75rem;
            border: 1px solid black;
            
          } 

        }

        
      }
    }
`


export const AccordionPrice = ({ price }) => {

    const {
        nazwa, cennik
    } = price


    const [expanded, setExpanded] = useState(false)

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false)
    };
    return (
        <StyledAccordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
                expandIcon={<MdKeyboardArrowDown size="25" color="#1B8640" />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <StyledHeadingDescriptionTreatment h4 as='h4'>
                    {nazwa}
                </StyledHeadingDescriptionTreatment>
            </AccordionSummary>
            <AccordionDetail>
                
                <div dangerouslySetInnerHTML={ {__html: cennik.childMarkdownRemark.html}}/>
       
            </AccordionDetail>
        </StyledAccordion>
    )
}